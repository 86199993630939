<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-row v-if="retrieve_user_rollovers">
        <b-col
          lg="6"
          md="6"
        >
          <b-card
            class="calc"
            title="Dados cadastrais"
          >
            <b-card-text><b>Nome:</b> {{ user | userDocument("FULLNAME") }}</b-card-text>
            <b-card-text><b>Email:</b> {{ user | userDocument("EMAIL") }}</b-card-text>
            <b-card-text><b>Cód convite:</b> {{ user | userDocument("INVITECODE") }}</b-card-text>
            <b-card-text><b>CPF:</b> {{ user | userDocument("CPF") }}</b-card-text>
            <b-card-text><b>Data de cadastro:</b> {{ $datter.convert(user.created_at) }}</b-card-text>
            <b-card-text><b>BTAG:</b> {{ user | userDocument("CLICKID") }}</b-card-text>
          </b-card>
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <b-card
            class="calc"
            title="Informações básicas"
          >
            <b-card-text><b>Saldo atual:</b> {{ wallets.data.filter(d => d.wallet_type_id == 1).length > 0 ? wallets.data.filter(d => d.wallet_type_id == 1)[0].balance : "Sem Info" | brl }} Rol[{{ getWalletRollover(1) | brl }}]</b-card-text>
            <b-card-text><b>Bonus em VB:</b>{{ wallets.data.filter(d => d.wallet_type_id == 2).length > 0 ? wallets.data.filter(d => d.wallet_type_id == 2)[0].balance : "Sem Info"| brl }} Rol[{{ getWalletRollover(2) | brl }}]</b-card-text>
            <b-card-text><b>Bonus em Casino:</b>{{ wallets.data.filter(d => d.wallet_type_id == 3).length > 0 ? wallets.data.filter(d => d.wallet_type_id == 3)[0].balance : "Sem Info"| brl }} Rol[{{ getWalletRollover(3) | brl }}]</b-card-text>
            <b-card-text><b>Bonus em Sala:</b>{{ wallets.data.filter(d => d.wallet_type_id == 5).length > 0 ? wallets.data.filter(d => d.wallet_type_id == 5)[0].balance : "Sem Info"| brl }} Rol[{{ getWalletRollover(5) | brl }}]</b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="retrieve_user_rollovers">
        <b-col
          lg="6"
          md="6"
        >
          <b-card
            class="calc"
            title="Creditar/Debitar Saldo"
          >
            <b-form-group>
              <div v-for="item in this.balance">
                <label
                  class="mt-1"
                  for="balance.real"
                >Saldo {{ item.title }}</label>
                <b-form-input
                  v-model="item.value"

                  icon-no-border
                  class="input-rounded-full"
                />
              </div>
              <hr>
              <label
                class="mt-1"
                for="description"
              >Descripcion</label>
              <b-form-input
                v-model="description"

                icon-no-border
                class="input-rounded-full"
              />

            </b-form-group>

          </b-card>
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <b-card
            class="calc"
            title="Preview"
          >
            <b-card-text v-if="balance.find(x=>x.wallet_type_id==1).value != 0"><b>Saldo novo:</b> {{ wallets.data.filter(d => d.wallet_type_id == 1).length > 0 ? parseFloat(wallets.data.filter(d => d.wallet_type_id == 1)[0].balance )+parseFloat(balance.find(x=>x.wallet_type_id==1).value ): "Sem Info" | brl }} </b-card-text>
            <b-card-text v-if="balance.find(x=>x.wallet_type_id==2).value != 0"><b>Bonus novo em VB:</b>{{ wallets.data.filter(d => d.wallet_type_id == 2).length > 0 ? parseFloat(wallets.data.filter(d => d.wallet_type_id == 2)[0].balance) + parseFloat(balance.find(x=>x.wallet_type_id==2).value): "Sem Info"| brl }} </b-card-text>
            <b-card-text v-if="balance.find(x=>x.wallet_type_id==3).value != 0"><b>Bonus novo em Casino:</b>{{ wallets.data.filter(d => d.wallet_type_id == 3).length > 0 ? parseFloat(wallets.data.filter(d => d.wallet_type_id == 3)[0].balance )+parseFloat( balance.find(x=>x.wallet_type_id==3).value): "Sem Info"| brl }} </b-card-text>
            <b-card-text v-if="balance.find(x=>x.wallet_type_id==4).value != 0"><b>Bonus novo em CasinoLive:</b>{{ wallets.data.filter(d => d.wallet_type_id == 3).length > 0 ? parseFloat(wallets.data.filter(d => d.wallet_type_id == 3)[0].balance) + parseFloat(balance.find(x=>x.wallet_type_id==4).value): "Sem Info"| brl }} </b-card-text>

            <b-card-text v-if="balance.find(x=>x.wallet_type_id==5).value != 0"><b>Bonus novo em Sala:</b>{{ wallets.data.filter(d => d.wallet_type_id == 5).length > 0 ? parseFloat(wallets.data.filter(d => d.wallet_type_id == 5)[0].balance )+ parseFloat(balance.find(x=>x.wallet_type_id==5).value): "Sem Info"| brl }}</b-card-text>

            <hr>

            Con descripcion:
            <b>{{description}}</b>
            <b-button
              class="act-button mt-2"
              variant="gradient-primary"
              @click="send"
            >
              Aplicar Modificaciones
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

import {
  BCard, BTabs, BTab, BButton, BCardText, BLink, BFormInput, BCol, BRow, BModal,
} from 'bootstrap-vue'

import ClientDepositSidebar from '@/components/clients/ClientDepositSidebar.vue'
import ClientAddNotesSidebar from '@/components/clients/ClientAddNotesSidebar.vue'
import ClientChangeNameSidebar from '@/components/clients/ClientChangeNameSidebar.vue'
import ClientAddPromotionSidebar from '@/components/clients/ClientAddPromotionSidebar.vue'
import ClientBtagSidebar from '@/components/clients/ClientBtagSidebar.vue'
import GeneralClientsList from '@/components/clients/GeneralClientsList.vue'
import GeneralGroupsList from '@/components/groups/GeneralGroupsList.vue'
import ClientStats from '@/components/clients/ClientStats.vue'
import ClientListInfo from '@/components/clients/ClientListInfo.vue'
import ClientTransactions from '@/components/clients/ClientTransactions.vue'
import ClientMovements from '@/components/clients/ClientMovements.vue'
import ClientStickyNotes from '@/components/clients/ClientStickyNotes.vue'
import ClientAddRelatedModal from '@/components/clients/ClientAddRelatedModal.vue'
import ClientAddGroupModal from '@/components/clients/ClientAddGroupModal.vue'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'

export default {
  components: {
    BButton,
    BTab,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BCol,
    BRow,
    BTabs,
    BModal,
    ClientDepositSidebar,
    ClientChangeNameSidebar,
    ClientAddPromotionSidebar,
    ClientStats,
    ClientAddRelatedModal,
    BLink,
    underscore: _,
    ClientAddNotesSidebar,
    ClientListInfo,
    ClientTransactions,
    ClientStickyNotes,
    GeneralClientsList,
    LogoLoaderAnimation,
    GeneralGroupsList,
    ClientAddGroupModal,
    ClientMovements,
    ClientBtagSidebar,
  },
  data() {
    return {
      description: 'test',
      balance: [{ wallet_type_id: 1, title: 'real', value: 0 },
        { wallet_type_id: 2, title: 'vb', value: 0 },
        { wallet_type_id: 3, title: 'casino', value: 0 },
        { wallet_type_id: 4, title: 'casino live', value: 0 },
        { wallet_type_id: 5, title: 'sala', value: 0 }],
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Customers',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: 'Products',
          customClass: 'mb-2 mb-sm-0',
        },
      ],
      user: { id: '0' },
      wallets: null,
      walletsToBeSearched: ['0'],
      depositObj: null,
      movements: null,
      openOrders: null,
      key: 0,
      relatedUsers: [],
      withdraw_orders: null,
      rKey: 1,
    }
  },
  apollo: {
    retrieve_user_rollovers: {
      query: gql`query($userId:ID!){
        retrieve_user_rollovers(user_id:$userId){
          id,
          wallet_id,
          act_sum,
          total_sum
        }
      }`,
      client: 'walletClient',
      variables() {
        return {
          userId: this.$route.params.clientId,
        }
      },
    },
    user_with_all_relations: {
      query: gql`query($userId:ID!){
        user_with_all_relations(id:$userId){
          id,
          username,
          status,
          created_at,
          documents{
            id,
            type,
            value,
            status
          },
          user_relations{
            id,
            relation_type{
              code
            },
            related_user{
              id,
              username,
              status,
              created_at,
              documents{
                id,
                type,
                value
              }
            },
            related_to_me{
              id,
              username,
              status,
              created_at,
              documents{
                id,
                type,
                value
              }
            }
          },
        }
      }`,
      client: 'identityClient',
      variables() {
        return {
          userId: this.$route.params.clientId,
        }
      },
    },
    wallets: {
      query: gql`query($userId:String!){
        wallets(first:100,user_id:$userId){
          data{
            user_id,
            wallet_code,
            wallet_type_id,
            balance,
            id
          },
        }
      }`,
      client: 'walletClient',
      variables() {
        return {
          userId: this.$route.params.clientId,
        }
      },
    },
    user_groups_by_user_id: {
      query: gql`query($userId:ID){
        user_groups_by_user_id(user_id:$userId){
          id,
          name,
          members_count,
          created_at
        }
      }`,
      client: 'identityClient',
      variables() {
        return {
          userId: this.$route.params.clientId,
        }
      },
    },
  },
  watch: {
    wallets() {
      const obj = _.pluck(this.wallets.data, 'id')
      this.walletsToBeSearched = obj
      console.log('wallets', this.wallets.data)
      this.balance = this.wallets.data.map(i => new { wallet_type_id: i.wallet_type_id, balance: i.balance }())
    },
    user_groups_by_user_id() {
      this.user_groups = this.user_groups_by_user_id
    },
    user_with_all_relations() {
      this.user = this.user_with_all_relations
      const plucky = _.pluck(this.user.user_relations, 'related_user').filter(r => r.id != this.user.id)
      this.relatedUsers = _.pluck(this.user.user_relations, 'related_to_me').filter(r => r.id != this.user.id)
      this.relatedUsers.push(...plucky)
    },

  },
  methods: {
    send() {
      const mods = this.balance.filter(x => x.value != 0)

      for (const n in mods) {
        console.log('modifico', mods[n])

        const item = mods[n]
        const wallet = this.wallets.data.filter(d => d.wallet_type_id == item.wallet_type_id)[0]

        const movement_type_id = 23
        let value = item.value

        let path = '/movement/credit'
        if (value < 0) {
          value *= -1
          path = '/movement/debit'
        }
        const json_user = window.localStorage.getItem('userData')
        const user_obj = JSON.parse(json_user)
        fetch(config.VUE_CONFIG_WALLET_API.replace('/graphql', '') + path, {
          headers: {
            'x-token-api': '64069316-3f27-4185-8f9b-d6bbd3812a9f',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            wallet_id: wallet.id,
            value: value * 1,
            description: this.description,
            movementTypeId: movement_type_id,
            walletSessionId: null,
            externalReference: uuidv4(),
            tags: { adm_user: user_obj.uid },
          }),
        }).then(response => {
          if (response.status === 200) {
            this.$dialog.alert({ title: 'success', text: 'Saldo atualizado com sucesso', okText: 'Ok' })
          } else {
            this.$dialog.alert({ title: 'error', text: 'Ocorreu um erro ao atualizar o saldo'+JSON.stringify(response), okText: 'Ok' })
          }
        }).catch(error => {
          this.$dialog.alert({ title: 'error', text: 'Ocorreu um erro ao atualizar o saldo'+ JSON.stringify(error), okText: 'Ok' })
        })
      }
    },
    getWalletRollover(walletTypeId) {
      const walletId = this.wallets.data.filter(d => d.wallet_type_id == walletTypeId)[0].id
      const rturn = this.retrieve_user_rollovers.filter(r => r.wallet_id == walletId)
      if (rturn.length > 0) {
        return rturn[0].act_sum
      }
      return 0
    },
    alterDoc(id, status) {
      const docId = id
      const docStatus = status
      this.$apollo.mutate({
        mutation: gql`mutation (
          $id: ID!,
          $status: String,
        ) {
          updateUserDocument(
            id: $id,
            status: $status
          ){
            id
          }
        }`,
        variables: {
          id: docId,
          status: docStatus,
        },
        client: 'identityClient',
      }).then(data => {
        this.$apolloProvider.clients.identityClient.reFetchObservableQueries()
      }).catch(error => {
        console.error(error)
      })
    },
    openDepositSidebar(e, ref) {
      this.depositObj = e
      this.$refs[ref].toogleV()
    },
    refreshOrders() {
      this.$apolloProvider.clients.cashioClient.reFetchObservableQueries()
    },
    refreshIdentity() {
      this.$apolloProvider.clients.identityClient.reFetchObservableQueries()
    },
    openButtonSidebar(e) {
      this.$refs[e].toogleV()
    },
    removeUserRelation(e) {
      const res = this.$dialog.confirm({ title: 'Remover relação', text: 'Voce realmente gostaria de desvincular os usuários?', actions: [{ text: 'Não', variant: 'red', key: false }, { text: 'Sim, remover', variant: 'green', key: true }] }).then(valor => {
        if (valor === true) {
          this.unsetRelation = e
          this.$apollo.mutate({
            mutation: gql`mutation (
              $id: ID!,
            ){
              deleteUserRelation(
                id: $id,
              ){
                id
              }
            }`,
            variables: {
              id: this.unsetRelation,
            },
            client: 'identityClient',
          }).then(data => {
            this.$apolloProvider.clients.identityClient.reFetchObservableQueries()
          }).catch(error => {
            console.error(error)
          })
        }
      }, motivo => {
      // rejeitada
      })
    },
    showRelatedModal() {
      this.$refs.relatedModal.$bvModal.show('user-related-modal')
    },
    showGroupModal() {
      this.$refs.groupModal.$bvModal.show('user-group-modal')
    },
    refreshGroups(el_group) {
      this.user_groups = el_group
      this.rKey++
    },
  },
}
</script>

<style lang="scss">
  .act-button{
    min-width: 130px;
    display: block;
    margin: 10px auto;
  }
  #blacklist-flag{
    background:#ea5455;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    margin: 10px 20px 0;
    font-size: 20px;
  }

  #good-client-flag{
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    font-family: "Century Gothic", "Helvetica", sans-serif;
    font-size: 25;
    font-weight: bold;
    background:radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 60%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62%, #5d4a1f 100%);
    margin: 10px 20px;
    position: relative;
    overflow: hidden;
    top: 25px;
  }
  #demo-client-flag{
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    font-size: 25;
    font-weight: bold;
    background:transparent;
    border:1px solid yellow;
    margin: 10px 20px;
    position: relative;
    overflow: hidden;
    top: 25px;
    span{
      color:yellow;

    }
  }
  /* Darker text on medium background */
  .plate{
    color: transparent;
    text-shadow: 0px 1px 0px rgba(255,255,255,.5); /* 50% white from bottom */
  }
  #good-client-flag:after {
  animation: shine 5s ease-in-out  infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 600%;
  height: 600%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.7) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

.icon:active:after {
  opacity: 0;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.15s, 0.15s, 0.30s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
</style>
